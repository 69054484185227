import $axios from '@/utils/request'

// 上传图片
export function uploadMaps(data) {
    const url = '/common/file/uploadMaps'
    return $axios.fPost(url, data)
}

// 获取服务器当前时间
export function serverTime(data) {
    const url = '/edu/other/now'
    return $axios.fGet(url, data)
}